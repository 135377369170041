import React from 'react';
import CustomEditor from './CustomEditor'; // импорт CustomEditor
import { Button } from 'react-bootstrap';

const TableEditor = ({ tableData, onChange }) => {
  const addRow = () => {
    onChange(!tableData ? [{ desc1: "", desc2: "" }] : [...tableData, { desc1: "", desc2: "" }]);
  };

  const deleteRow = (index) => {
    const newData = tableData.filter((_, i) => i !== index);
    onChange(newData);
  };

  return (
    <div className='rules'>
      <table>
        <thead>
          <tr>
            <th>Нарушение</th>
            <th>Санкции</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.map((row, index) => (
            <tr key={index}>
              <td>
                <CustomEditor
                  content={row.desc1 || ""}
                  onChange={(e) => {
                    const newData = [...tableData];
                    newData[index].desc1 = e;
                    onChange(newData);
                  }}
                />
              </td>
              <td>
                <CustomEditor
                  content={row.desc2 || ""}
                  onChange={(e) => {
                    const newData = [...tableData];
                    newData[index].desc2 = e;
                    onChange(newData);
                  }}
                />
              </td>
              <td>
                <Button onClick={() => deleteRow(index)}>Удалить</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button onClick={addRow}>Добавить строку</Button>
    </div>
  );
};

export default TableEditor;
