import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createCert } from "../../services/cert";
import TableEditor from "../../components/editor/TableEditor";

const СertCreate = () => {
  const navigate = useNavigate();
  const [editImageSale, setEditImageSale] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createCert(data)
      .then(() => {
        NotificationManager.success("Документ успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [data]);

  return (
    <Container fluid={true}>
      <Meta title="Создать документ" />
      <section className="box">
        <div className="d-flex justify-content-between align-items-center">
          <Link
            to="/documents"
            className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
          >
            <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
          </Link>
          <button
            className="btn-primary"
            disabled={!isValid}
            onClick={() => onSubmit()}
          >
            Сохранить изменения
          </button>
        </div>
        <h3 className="mb-4">Создать документ</h3>

        <div className="mb-3">
          <Input
            label="Название"
            name="title"
            placeholder="Заголовок"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="URL адрес"
            name="link"
            placeholder="url адрес"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Порядок"
            name="priority"
            placeholder="0"
            errors={errors}
            register={register}
            validation={{
              required: "Обязательное поле",
            }}
          />
        </div>
        <Row>
          <Col md={12} className="doc">
            <CustomEditor
              content={data.content}
              onChange={(e) => setValue("content", e)}
            />
          </Col>
          <h3 className="mt-3">Дополнительные поля для правила сайта</h3>
          <div>
            <TableEditor tableData={data.data} onChange={e => setValue("data", e)} />
          </div>
          <Col md={12} className="doc mt-3">
            <CustomEditor
              content={data.desc}
              onChange={(e) => setValue("desc", e)}
            />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default СertCreate;
